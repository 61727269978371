
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import Button from '@/components/Button.vue'
import Collapsable from '~/components/Collapsable/Collapsable.vue'
import ValuesModal from '@/components/StudyCreation/ValuesModal.vue'
import ValuesBox from '@/components/StudyCreation/ValuesBox.vue'
import CheckBox from '@/components/StudyCreation/CheckBox.vue'

export default defineComponent({
  name : 'Category',
  components: {
    Button,
    Collapsable,
    ValuesModal,
    ValuesBox,
    CheckBox
  },
  props:{
    title: {
      type: String,
      default: 'Banca'
    },
    questions:{
      type:Array,
      default: () => []
    }
  },
  computed:{
    questionsAsValues():any{
      const questions = this.questions.map(function(q:any,i){
        return { id: q.id, value: q.clientText, selected: q.selected}
      })
      return questions
    },
    hasQuestionsSelected(){
      let selected = false
      for(let q of this.questions){
        const question: any = q
        if(question.selected)  selected = true
      }
      return selected
    }
  },
  data(){
    return{
      collapsed: false,
      showModal: false,
      showValuesModal: false,
      currentQuestion: {}
    }
  },
  methods:{
    valuesFormated(values: any){
      return values.map(function(v: any){
        return {id:v.id, value:v.value, selected:v.selected}
      })
    },
    openModal(){
      this.showModal = true
    },
    closeModal(){
      this.showModal = false
      this.$emit('close-modal')
    },
    closeValuesModal(questionId: any){
      this.showValuesModal = false
      this.$emit('close-values-modal',questionId)
    },
    openValuesModal(q: any){
      this.currentQuestion = q
      this.showValuesModal = true
    },
    saveQuestions(event: any){
      const question = this.questions.filter(function(q: any){
        return q.id == event.singleSelected
      })[0]
      this.$emit('save-questions',event.values)
      this.openValuesModal(question)
    }
  }
})
