import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "StudyIcon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type == 'WAT_CORPORATE')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require('~/assets/img/studyIcons/corporate.svg'),
          alt: ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.type == 'WAT_FOCUS')
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: require('~/assets/img/studyIcons/focus.svg'),
          alt: ""
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.type == 'WAT_SURVEY')
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: require('~/assets/img/studyIcons/survey.svg'),
          alt: ""
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.type == 'WAT_LAB')
      ? (_openBlock(), _createElementBlock("img", {
          key: 3,
          src: require('~/assets/img/studyIcons/watlab.svg'),
          alt: ""
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.type == 'WAT_UX' && _ctx.subType?.length == 0)
      ? (_openBlock(), _createElementBlock("img", {
          key: 4,
          src: require('~/assets/img/studyIcons/watux.svg'),
          alt: ""
        }, null, 8, _hoisted_6))
      : _createCommentVNode("", true),
    (_ctx.subType == 'WAT_UX')
      ? (_openBlock(), _createElementBlock("img", {
          key: 5,
          src: require('~/assets/img/studyIcons/watux-sub.svg'),
          alt: ""
        }, null, 8, _hoisted_7))
      : _createCommentVNode("", true),
    (_ctx.subType == 'WAT_UX_QUANTI')
      ? (_openBlock(), _createElementBlock("img", {
          key: 6,
          src: require('~/assets/img/studyIcons/watuxquanti-sub.svg'),
          alt: ""
        }, null, 8, _hoisted_8))
      : _createCommentVNode("", true),
    (_ctx.type == 'CLICK_TEST')
      ? (_openBlock(), _createElementBlock("img", {
          key: 7,
          src: require('~/assets/img/studyIcons/clicktest.svg'),
          alt: ""
        }, null, 8, _hoisted_9))
      : _createCommentVNode("", true),
    (_ctx.type == 'WAT_NEURO')
      ? (_openBlock(), _createElementBlock("img", {
          key: 8,
          src: require('~/assets/img/studyIcons/clicktest.svg'),
          alt: ""
        }, null, 8, _hoisted_10))
      : _createCommentVNode("", true)
  ]))
}